import '../styles/home.css';

import React, { useRef, useState } from 'react';

import ChannelsSection from '../components/home/channels-section';
import FormPopup from '../components/shared/form-popup';
import Hero from '../components/shared/hero';
import ImageSection from '../components/home/image-section';
import Layout from '../components/layout';
import PackageSection from '../components/home/package-section';
import SEO from '../components/seo';
import ScrollAnimation from 'react-animate-on-scroll';
import { graphql } from 'gatsby';
import meta from '../constants/meta-descriptions';

const HomePageUndefinite = ({ data }) => {
	const [showForm, setShowForm] = useState(false);
	let packageRef = useRef(null);

	let steps = [];
	data.wordpressPage.acf.steps.split('\n').map((rString) => {
		let parts = rString.split('###');

		steps.push({
			title: parts[0],
			text: parts[1]
		});
	});

	return (
		<>
			<div>
				<Layout activeTab={'/za-doma-bezsrochni'}>
					<SEO
						title={meta.homeIndefinite.title}
						description={meta.homeIndefinite.metaDescription}
						isNoIndex
					/>
					<Hero
						sectionId="hero-za-doma-bezsrochni"
						imageClass="home-packages-hero"
						containerClass="col-12 col-lg-7 hero-text p-fixed-za-doma-bezsrochni"
						title={
							<div className="home-packages-hero-text">
								<div className="hero-title-bezsrochni">
									Търсиш ТВ и НЕТ без договор? Дошъл си на правилното
									място!
								</div>
							</div>
						}
						titleWrapperClasses={'home-packages-hero-wrapper container px-0'}
					/>
					<section
						id="packages-hero"
						className="spacing-bottom"
						ref={packageRef}
					>
						<PackageSection
							isIndexPage={false}
							currPage="za-doma-bezsrochni"
							enableToggle={true}
						/>
					</section>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<ImageSection isIndefinite={true} />
					</ScrollAnimation>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<ChannelsSection />
					</ScrollAnimation>
				</Layout>

				<FormPopup show={showForm} setShow={setShowForm} isBusiness={false} />
			</div>
		</>
	);
};

export const query = graphql`
	query HomePageQueryUndefinite {
		bgImage: file(relativePath: { eq: "home/cta-bg.png" }) {
			childImageSharp {
				fluid(maxHeight: 1500) {
					src
				}
			}
		}
		heroImage: file(relativePath: { eq: "home/home-packages-hero.png" }) {
			childImageSharp {
				fluid(maxHeight: 800) {
					...GatsbyImageSharpFluid
				}
			}
		}
		heroImagePermanent: file(relativePath: { eq: "home/permanent.webp" }) {
			childImageSharp {
				fluid(maxHeight: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		heroImageRegular: file(relativePath: { eq: "home/regular.webp" }) {
			childImageSharp {
				fluid(maxHeight: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		wordpressPage(slug: { eq: "za-doma-bezsrochni" }) {
			acf {
				steps
			}
		}
		allWordpressWpFaqs(filter: { acf: { category: { eq: "za-doma" } } }) {
			edges {
				node {
					title
					content
				}
			}
		}
	}
`;

export default HomePageUndefinite;

