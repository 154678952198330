import '../../styles/za-doma-hero.css';

import React from 'react';

const Hero = ({ title, sectionId,containerClass }) => {
	return (
		<>
			<section id={sectionId}>
				<div className="container pb-5 position-relative hero-top">
					<div className={containerClass}>
						<div className="text-left mx-0 mx-md-0 px-0 px-md-0 pb-2">
							<h1 className="d-inline my-lg-3 text-white">{title}</h1>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Hero;

