import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { ChannelRow } from '../shared/popup-channels';
import PopupChannels from '../shared/popup-channels';
import SectionTitle from '../shared/section-titles';
import styled from 'styled-components';

const ChannelsSection = () => {
	const data = useStaticQuery(graphql`
		query ChannelsSection {
			favs: allGoogleStorage(filter: { name: { regex: "/favourites/" } }) {
				edges {
					node {
						name
						signedUrl
					}
				}
			}
		}
	`);


	const channelInfo = [
		{
			title: 'Най-гледани',
			channelData: data.favs.edges.filter(({ node }) => {
				return node.name.includes('Най-гледани');
			})
		},
		{
			title: 'Филми/Сериали',
			channelData: data.favs.edges.filter(({ node }) => {
				return node.name.includes('Филми Сериали');
			})
		},
		{
			title: 'Спорт',
			channelData: data.favs.edges.filter(({ node }) => {
				return node.name.includes('Спорт');
			})
		},
		{
			title: 'Музика',
			channelData: data.favs.edges.filter(({ node }) => {
				return node.name.includes('Музика');
			})
		},
		{
			title: 'За децата',
			channelData: data.favs.edges.filter(({ node }) => {
				return node.name.includes('За децата');
			})
		},
		{
			title: 'Документални',
			channelData: data.favs.edges.filter(({ node }) => {
				return node.name.includes('Документални');
			})
		},
		{
			title: 'HD',
			channelData:  data.favs.edges.filter(({ node }) => {
				return node.name.includes('HD');
			})
		}
	];

	return (
		<>
			<section id="channels" className="centering spacing-top"></section>
			<div className="row">
				<SectionTitle>
					<h2>
						Любимата ти телевизия
						<br />
						от всеки жанр
					</h2>
					<hr className="center" />
				</SectionTitle>

				<div className="container mt-4">
					<TabularSection channels={channelInfo} />
				</div>
			</div>
		</>
	);
};

const ScrollChannels = styled.div`
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	&::-webkit-scrollbar {
		display: none;
	}
`;

const Item = styled.div`
	display: inline-block;
`;

const TabularSection = ({ channels }) => {
	const [selectedTab, setTab] = useState(0);
	const [show, setShow] = useState(false);

	return (
		<div>
			<ScrollChannels>
				{channels.map(({ title }, index) => (
					<Item key={index}>
						<button
							className={
								'clear channel-selection px-4 d-inline-block ' +
								(selectedTab === index && 'active')
							}
							onClick={(e) => {
								setTab(index);
							}}
						>
							<h3 className="d-inline-block">{title}</h3>
						</button>
					</Item>
				))}
			</ScrollChannels>
			<div className="my-4 text-center">
				<ChannelRow channels={channels[selectedTab].channelData} />
				<button
					onClick={(_) => setShow(true)}
					type="button"
					className="btn btn-secondary-no-outline mt-5"
				>
					Виж целия списък
				</button>
			</div>

			<PopupChannels
				show={show}
				setShow={(v) => {
					setShow(v);
				}}
			/>
		</div>
	);
};

export default ChannelsSection;

